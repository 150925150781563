import * as THREE from "three"
import { SVGLoader } from "three/examples/jsm/Addons"


export class Coin{
	scene : THREE.Scene;
	pivot : THREE.Object3D;
	score_callback : (arg0: number) => void;
	coin_height : number

	constructor(scene: THREE.Scene, score_callback){
		this.pivot = new THREE.Object3D();
		this.scene = scene;
		this.score_callback = score_callback;
	}

	add_to_scene = (initial_rotation : number, min_coin_height : number) =>{
			
		const max_coin_height = 40;
		const diff = Math.min(5,(max_coin_height - min_coin_height));
		this.coin_height = min_coin_height + (Math.random() * diff);
		console.log("Coin height " + this.coin_height);
		const loader = new THREE.TextureLoader();

		loader.load("dollar.png", ( texture ) => {
			const geometry = new THREE.CircleGeometry(1,32);
			const material = new THREE.MeshBasicMaterial({map : texture});
			const mesh = new THREE.Mesh(geometry, material);
			mesh.position.x = this.coin_height;
			this.pivot.position.y = -2;
			this.pivot.add(mesh);
			this.scene.add(this.pivot);
			this.pivot.rotation.z = THREE.MathUtils.degToRad(initial_rotation);
		});
	};

	animate = (multiplier : number) =>{
		// Multiplier here just controls how fast it goes
		// coins don't fall to the earth
		this.pivot.rotateOnAxis(new THREE.Vector3(0,0,1), 0.01 * multiplier)
	}

	claim_or_delete(satellite_y_position: number){
		const current_rotation_deg = THREE.MathUtils.radToDeg(this.pivot.rotation.z);
		if(current_rotation_deg > 145){
			return true;
		}
		if(current_rotation_deg > 89 && current_rotation_deg < 91){
			if(Math.abs(satellite_y_position + 1 - this.coin_height) <= 2){
				this.score_callback(100 * (45 / this.coin_height));
				return true;
			}
		}
	}
}
		

export class Coinhandler{

};