import * as THREE from "three";
import { SVGLoader } from "three/examples/jsm/Addons";

export class TumblingCollisionObject{
	scene: THREE.Scene;
	position: THREE.Vector3;
	movement_vector: THREE.Vector3;

	_group: THREE.Group;
	_rotation_deg: number;

	constructor(scene: THREE.Scene, satellite_location: THREE.Vector3, height_min: number = 35, height_max: number = 50, variation: number = 10){
		this.scene = scene;
		this._rotation_deg = 0.0;
		this._group = new THREE.Group();

		// Initialise the position as a random 3D point
		this.position = new THREE.Vector3(
			// A random number between 35 and 50
			height_min + (Math.random() * (height_max - height_min)),
			// Satellite location y +/- variation
			satellite_location.y + (Math.random() * (2 * variation)) - variation,
			// A random number between 0 and -5
			0 - (Math.random() * 5)
		);

		// Determine the normalised movement vector between position 
		// and the satellite location

		// Random offset between 5 and 10
		// const satellite_y_offset = 5 + (Math.random() * 5);

		this.movement_vector = satellite_location.clone()
			// new THREE.Vector3(0,-satellite_y_offset,0)
		.sub(this.position).normalize();

		const loader = new SVGLoader();
		
		const object_icons = ["sputnik.svg", "another_satellite.svg", "rocket.svg", "ufo.svg"];
		// Chose a random icon from the array
		const svg_choice = object_icons[Math.floor(Math.random() * object_icons.length)];

		loader.load(svg_choice, ( data ) => {
			this._group.scale.multiplyScalar( 0.005 );
			this._group.position.copy(this.position);
			let renderOrder = 0;

			for ( const path of data.paths ) {

				const fillColor = path.userData.style.fill;

				if ( fillColor !== 'none' ) {

					const material = new THREE.MeshBasicMaterial( {
						color: new THREE.Color().setStyle( fillColor ),
						opacity: path.userData.style.fillOpacity,
						transparent: true,
						side: THREE.DoubleSide,
						depthWrite: false,
					} );

					const shapes = SVGLoader.createShapes( path );

					for ( const shape of shapes ) {

						const geometry = new THREE.ShapeGeometry( shape );
						const mesh = new THREE.Mesh( geometry, material );
						mesh.renderOrder = renderOrder ++;

						this._group.add( mesh );
					}

				}
				else{
					console.log("Fill colour is none.");
				}
			}
			this.scene.add(this._group);
		});
	}
	
	animate = () => {
		this._rotation_deg += 1;
		if(this._rotation_deg >= 360.0){
			this._rotation_deg = 0.0;
		}
		this._group.position.add(this.movement_vector.clone().multiplyScalar(0.05));
		this._group.rotation.z = THREE.MathUtils.degToRad(this._rotation_deg);
	}

	did_collide = (satellite_position: THREE.Vector3) => {
		return (this._group.position.x <= 0.5 && this._group.position.x >= -0.5) && Math.abs(
			this._group.position.distanceTo(satellite_position)
		) < 2;
	}

	out_of_play = () => {
		return this._group.position.x < -30 ||  this._group.position.y > 50 || this._group.position.z > 28;
	}

	remove_from_scene = () => {
		this.scene.remove(this._group);
	}
}